//Dependencies
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
//import Storage from "../../services/localStorage.service";
import Layout from "../../components/layouts";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as thankyouStyles from "./thankyou.module.scss"

// Assets

const INGRID_SITE_ID = "b99571d3-3173-4ffd-bb66-0b518255d4ca"


const Tracking = ({ location, pageContext }) => {

    const { t } = useTranslation();
    
    const [ingridLoaded, setIngridLoaded] = useState(false)

    const containerId = "delivery-tracking-widget"
  
    const trackingLocales = {
        se: "sv-SE",
        dk: "da-DK",
        eu: "en-US",
        no: "no-NO",
        nl: "nl-NL",
    }
  
    const addTrackingWidget = locale => {
      console.log(window.IngridDeliveryTrackingWidgetApi)
      if (window.IngridDeliveryTrackingWidgetApi) {
        window.IngridDeliveryTrackingWidgetApi.renderSearchMode({
          containerId,
          siteId: INGRID_SITE_ID,
          locale,
        })
        //setIngridLoaded(true)
      } else {
        setIngridLoaded("again")
      }
    }
  
    const clearContainer = container => {
      while (container?.hasChildNodes()) {
        if (container.firstChild) {
          container.removeChild(container.firstChild)
        }
      }
    }
  
    useEffect(() => {
      const container = document.getElementById(containerId)
  
      if (!window || !container || ingridLoaded === true) return
      clearContainer(container)
      addTrackingWidget(
        trackingLocales[pageContext.country] || "en-US"
        )
  
      return () => {
        clearContainer(container)
      }
    }, [addTrackingWidget, ingridLoaded])


    return (
        <Layout country={pageContext.country} location={location}>
            <div className={thankyouStyles.cdTrackingPage}>
                <div className="cd-max-width">
                    <div className={thankyouStyles.cdThankyouContainer}>
                        <div className={thankyouStyles.cdTrackingContainer}>
                            <h3>{t('tracking.headline')}</h3>

                            {/* {! ingridConfig ? <div className={`${thankyouStyles.cdTrackingBox}`}>
                                <div className={styles.cdOsSignupLeft}>
                                    <h3>{t('tracking.subtitle')}</h3>
                                </div>
                                <div className={`${thankyouStyles.cdTrackingBoxInputs} ${styles.cdOsForm}`} >
                                    <input
                                        required={true}
                                        className={styles.cdSignupInput}
                                        type="text" ref={orderInput}
                                        placeholder={t('tracking.placeholder')}
                                    />
                                    <button className={styles.cdSignupButton} onClick={() => {searchOrder()}}>{t('tracking.search')}</button>
                                </div>
                            </div>
                            :
                            <>
                                <div className={thankyouStyles.cdTrackingBack} onClick={() => {setIngridConfig(false)}}>
                                    <i className="material-icons">keyboard_backspace</i>
                                    <span>{t('tracking.back')}</span>
                                </div>
                                <div id="cd-tracking-widget"></div>
                            </>
                            } */}

                            <div id={containerId}></div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Tracking